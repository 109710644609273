<template>
  <div>
    <div v-if="plans" class="container pt-3">
      <div class="text-center pt-4 pb-2">
        <h2>
          Review SolidProfessor Memberships and Pricing
        </h2>
      </div>
      <div v-if="oauth_login_message" class="row justify-content-center no-gutters">
        <div class="col-12 col-lg-8 text-center">
          <div class="alert alert-light text-center">
            <font-awesome-icon icon="exclamation-circle" />
            You don't have an account, but you can sign up for a membership here
          </div>
        </div>
      </div>
      <b-row class="pt-4">
        <b-col
          v-for="(card, cardIndex) in cards"
          :key="cardIndex"
          cols="12"
          lg="4"
          class="pb-5">
          <MembershipCard
            :card-data="card"
            :card-index="cardIndex"
            :plans-data="plans"
            @selected-plan="selectPlan" />
        </b-col>
      </b-row>
      <MembershipFooter />
    </div>
    <sp-loader v-else />
  </div>
</template>

<script>
import SpLoader from '@/components/SPLoader';
import MembershipCard from '@/components/Accounts/MembershipCard.vue';
import MembershipFooter from '@/components/Accounts/MembershipFooter.vue';
import redirectMixin from '@/utils/mixins/Redirect';

export default {
  name: 'SelectPlan',
  components: {
    'sp-loader': SpLoader,
    MembershipCard,
    MembershipFooter,
    redirectMixin
  },
  data() {
    return {
      oauth_login_message: false,
      cards: {
        individual: {
          background: '/images/backgrounds/navy-pattern-4.png',
          padding: 'mt-3',
          title: 'Individual Membership',
          cta: 'Monthly / Annual',
          footer_link_text: 'benefits of having an individual membership.',
          footer_link: '/individuals',
          bullet_points: [
            '5,000+ on-demand video tutorials',
            '100+ technical certificates',
            'Software certification prep',
            'Guided Learning Paths',
            'Easy search and concise lessons',
            'Hands-on training and exercises'
          ]
        },
        teams: {
          background: '/images/backgrounds/navy-pattern-4.png',
          padding: 'mt-4 px-4 px-lg-0',
          title: 'Teams Membership',
          cta: 'Elevate design performance and efficiency with online learning for your engineering team',
          footer_link_text: 'benefits of having a team membership.',
          footer_link: '/teams',
          button_text: 'Get a Free Demo for Your Team',
          button_link: '/teams/#pardotiframe',
          bullet_points: [
            'All individual membership features',
            'Dedicated onboarding support',
            'Customized training programs and assignments',
            'Admin dashboard and reporting',
            'Knowledge capture opportunities'
          ]
        },
        academic: {
          background: '/images/backgrounds/navy-pattern-4.png',
          padding: 'mt-4 px-2',
          title: 'Academic Membership',
          cta: 'Prepare K-12 and post-secondary students for a STEM career with online learning',
          footer_link_text: 'benefits of having an academic membership.',
          footer_link: '/schools',
          button_text: 'Get a Free Demo for Your School',
          button_link: '/schools/#pardotiframe',
          students: {
            title: 'Students: Trying to enroll in a class?',
            button_text: 'Go to Student Store'
          },
          bullet_points: [
            'Engineering design and methods',
            'Certification prep courses',
            'Free certification exam vouchers',
            'Small, bite-sized lessons',
            'Reporting system',
            'Library of 5,000+ courses',
            'Technology Grant Program'
          ]
        }
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // If oauthlogin query param show oauth message jhamm 4/3/18
      if (to.query.oauthlogin === '1') {
        vm.oauth_login_message = true;
      }
    });
  },
  computed: {
    isRoute() {
      return name => {
        return name === this.$route.name;
      };
    },
    plans() {
      return this.$store.getters['plans/getPlans'];
    },
  },
  methods: {
    selectPlan(plan) {
      this.$store.commit('plans/setSelectedPlan', plan);
      if (this.isLoggedIn) {
        this.$router.push({
          name: 'SignupPayment',
          params: {plan_id: plan.id},
        });
      } else {
        this.$router.push({name: 'SignupAccountInfo'});
      }
    },
    routeToApp() {
      this.redirectUrl(this.SPENV.APP_URL);
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
