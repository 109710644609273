<template>
  <div class="row no-gutters">
    <div class="col-12 text-center">
      <h3>Join a community of 300,000+ engineers</h3>
      <p class="pt-4">
        We've been trusted by engineers at leading companies and schools around the globe
      </p>
      <b-row class="pt-4">
        <b-col v-for="thumb in thumbs"
               :key="thumb.file"
               cols="4"
               sm="2">
          <b-img fluid
                 :src="SPENV.S3_URL + '/images/signup-thumbs/footer/' + thumb.file"
                 :alt="thumb.alt"
                 vertical-align="center" />
        </b-col>
      </b-row>
      <p class="pt-4">
        Questions? Visit our
        <a :href="SPENV.WWW_URL+'/contact-us/#faq'" class="btn-link">
          FAQs or Contact Us.
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MembershipFooter',
  data () {
    return {
      thumbs: [
        { file: '01.png', alt: 'Kawasaki' },
        { file: '02.png', alt: 'Medtronic' },
        { file: '03.png', alt: 'Subzero' },
        { file: '04.png', alt: 'University of California San Diego' },
        { file: '05.png', alt: 'Ivy Tech Community College' },
        { file: '06.png', alt: 'Katy Independent School District' }
      ]
    };
  }
};
</script>
