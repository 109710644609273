<template>
  <b-card
    overlay
    text-variant="white"
    :img-src="SPENV.S3_URL + cardData.background"
    img-alt="Image"
    img-top
    class="text-center h-100">
    <h4 class="font-weight-bold mt-xl-3 mt-lg-0 mt-3">
      {{ cardData.title }}
    </h4>
    <b-card-text>
      <h5 :class="cardData.padding" class="font-weight-light">
        {{ cardData.cta }}
      </h5>
      <h4 v-if="cardIndex == 'individual'">
        <span v-for="(plan, plan_index) in plansData.slice().reverse()" :key="plan_index" class="font-weight-bold">
          ${{ plan.price }}
          <span v-if="plan_index == 0">/</span>
        </span>
      </h4>
      <p v-if="cardIndex == 'individual'" class="mt-xl-4 mt-lg-2 mt-md-5 mt-sm-5 mt-4 mb-0">
        <small>
          Get two months free with an <br> annual membership
        </small>
      </p>
    </b-card-text>

    <div slot="footer" class="black-text text-left mt-4 position-relative">
      <div v-if="cardIndex == 'individual'">
        <span
          v-for="plan in plansData.slice().reverse()"
          :key="plan.id"
          class="button-span">
          <b-button
            v-if="user && user.membership && !user.membership.expired_at && user.isMember && user.membership.plan_id === plan.id"
            variant="primary"
            href="#"
            disabled>
            Current Plan
          </b-button>
          <b-button
            v-else
            variant="primary"
            class="text-capitalize"
            href="#"
            @click="$emit('selected-plan', plan)">
            Buy
            <span v-if="plan.interval === 'month'">
              Monthly
            </span>
            <span v-else>
              Annual
            </span>
          </b-button>
        </span>
      </div>
      <b-button
        v-if="cardIndex == 'teams' || cardIndex == 'academic'"
        block variant="primary"
        :href="SPENV.WWW_URL + cardData.button_link">
        {{ cardData.button_text }}
      </b-button>
      <span v-if="cardData.students">
        <p class="text-center mt-4"><small class="font-weight-bold">{{ cardData.students.title }}</small></p>
        <span class="text-center d-block pb-2">
          <b-button variant="outline-primary" size="sm" :to="{ name: 'StudentStore' }">{{ cardData.students.button_text }}</b-button>
        </span>
      </span>
      <b-list-group class="mt-3">
        <b-list-group-item
          v-for="bullet in cardData.bullet_points"
          :key="bullet"
          class="border-0 px-0 py-2">
          <b-row class="no-gutters">
            <b-col cols="1" lg="1">
              <span class="mr-2 text-primary">
                <font-awesome-icon icon="check" fixed-width />
              </span>
            </b-col>
            <b-col cols="11" lg="10">
              {{ bullet }}
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <p class="text-center mt-4">
        <small>
          Learn more about the
          <a class="btn-link"
             :href="SPENV.WWW_URL + cardData.footer_link">
            {{ cardData.footer_link_text }}
          </a>
        </small>
      </p>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'MembershipCard',
  props: {
    cardData: {
      type: Object,
      default() {
        return '';
      }
    },
    cardIndex: {
      type: String,
      default() {
        return '';
      }
    },
    plansData: {
      type: Array,
      default() {
        return '';
      }
    }
  },
  data () {
    return {
      selectedPlan: null
    };
  }
};
</script>
